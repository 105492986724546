import styled from 'styled-components';

export const FilterElement = styled.section`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4;
  animation: filterFadeOut .6s ease;
  
  &.is-shown {
    animation: filterFadeIn .6s ease forwards;
  }
  
  .filter-wrapper {
    background-color: var(--theme-white);
  }
  
  .wrapper {
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
  }
  
  .filter {
    display: flex;
    flex-direction: column;
    width: 1000px;
    padding: 0 20px 20px 0;
  }
  
  .sort {
    padding-bottom: 20px;
    
    .list {
      justify-content: flex-start;
    }
  }
  
  .title {
    font-weight: 400;
  }
  
  .list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  /*-------------------------
  # filterFadeIn
  --------------------------*/
  @keyframes filterFadeIn {
    0% {
      top: 0;
    }
    
    100% {
      top: 150px;
    }
  }
  
  /*-------------------------
  # filterFadeOut
  --------------------------*/
  @keyframes filterFadeOut {
    0% {
      top: 150px;
    }
    
    100% {
      top: 0;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    @keyframes filterFadeIn {
      0% {
        top: 0;
      }
      
      100% {
        top: 135px;
      }
    }
    
    @keyframes filterFadeOut {
      0% {
        top: 135px;
      }
      
      100% {
        top: 0;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .wrapper {
      flex-direction: column;
      justify-content: center;
    }
    
    .filter,
    .sort {
      position: relative;
      flex-direction: row;
      width: 100%;
      padding: 0;
    }
    
    .filter {
      padding-bottom: 5px;
      z-index: 2;
      
      .title {
        margin-right: 20px;
      }
      
      &--de {
        .title {
          margin-right: 40px; 
        }
      }
    }
    
    .sort {
      display: flex;
      z-index: 1;
      
      .title {
        margin-right: 26px;
      }
      
      &--de {
       .title {
          margin-right: 20px;
        } 
      }
    }
  }
`;