import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import constants
import { BACKEND_URL } from '../../../constants';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { SponsoredAdElement } from './styles/SponsoredAd';

const SponsoredAd = memo( ( { additionalClass } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          adds {
            button_link
            button_text
            image
            text
            title
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [texts, setTexts] = useState({
    title: '',
    text: '',
    logo: '',
    subTitle: '',
    btnLink: '',
    btnText: ''
  });

  const { title, text, logo, subTitle, btnLink, btnText } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { adds } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = adds[orderNumber];
      const { image, title, text, /*title2,*/ button_text, button_link } = current;

      setTexts({
        title,
        text,
        logo: image,
        subTitle: '',
        btnLink: button_link,
        btnText: button_text
      });
    });
  }, [data, defaultLang] );

  return (
    <SponsoredAdElement className={ additionalClass }>
      <span className="overflow-title">Sponsored ad</span>
      <div className="content">
        <img className="ad-logo"
             src={ BACKEND_URL + logo }
             alt="Marketing Monkeys Gmbh" />

        <h3 className="title">{ title }</h3>
        <p className="text">{ text }</p>
        { subTitle !== '' ? <span className="price">{ subTitle }</span> : false }
        <a className="link"
           href={ btnLink }
           target="_blank"
           rel="noreferrer nofollow">{ btnText }</a>
      </div>
    </SponsoredAdElement>
  )
});

SponsoredAd.defaultProps = {
  additionalClass: ''
};

SponsoredAd.propTypes = {
  additionalClass: PropTypes.string
};

export default SponsoredAd;