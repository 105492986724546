import React, { memo, useState, useEffect } from 'react';
import { useStaticQuery, graphql }          from 'gatsby';
import PropTypes                            from 'prop-types';

// import styles
import { LoadMoreBtnElement } from '../../../styles/elements/Post/LoadMoreBtn';

// import components
import ArrowRegularIcon from '../icons/ArrowRegularIcon';

const LoadMoreBtn = memo( ( { currentLang, onClick } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          categories_text {
            load_more
          }
        }
      }
    }
  `);

  // define the default component state
  const [text, setText] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { categories_text } = node;
      let orderNumber;

      switch ( currentLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setText( categories_text[orderNumber].load_more );
    });
  }, [data, currentLang] );

  /**
   * Processing click action.
   * Passing data to the received function
   */
  const handleClick = () => {
    if ( onClick !== undefined ) onClick();
  };

  return (
    <LoadMoreBtnElement>
      <button className="load" onClick={ handleClick }>
        { text }
        <span className="load__arrow">
          <ArrowRegularIcon className="load__arrow__icon"
                            color="#00B7F1" />
        </span>
      </button>
    </LoadMoreBtnElement>
  )
});

LoadMoreBtn.defaultProps = {
  currentLang: '',
  onClick: () => {}
};

LoadMoreBtn.propTypes = {
  currentLang: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default LoadMoreBtn;