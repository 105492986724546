import styled from 'styled-components';

export const PostListNoResultElement = styled.div`
  width: 100%;
  margin-bottom: 50px;
  border-top: 2px solid var(--theme-gray);
  border-bottom: 2px solid var(--theme-gray);
  padding: 30px 0;
  
  .no-results {
    margin: 0;
    padding: 40px 0;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 41px;
    letter-spacing: 1.25px;
    background-color: var(--theme-white);
  }
  
  @media (max-width: 991px) {
    .no-results {
      font-size: 30px;
      line-height: 34px;
    }
  }
  
  @media (max-width: 480px) {
    .no-results {
      font-size: 24px;
      line-height: 26px;
    }
  }
`;