import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useWindowSize, usePostsById } from '../../helpers';

// import styles
import { TrendingElement } from '../../styles/elements/Trending';

// import components
import TrendingPostPreview from './TrendingPostPreview';
import Title               from './Title';

const Trending = memo( ( { className, desktop, horizontalTablet, verticalTablet, mobile } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          trending {
            title
            post_ids
          }
        }
      }
      
      allPosts {
        nodes {
          postId
          id
          data {
            title
            slug
            short_description
            date
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [title, setTitle] = useState( '' );
  const [allPosts, setAllPosts] = useState( [] );
  const [trendingPosts, setTrendingPosts] = useState( [] );

  // define the custom hook
  const { width } = useWindowSize();
  const findingPosts = usePostsById( allPosts, trendingPosts );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { trending } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = trending[orderNumber];
      const { title, post_ids } = current;

      setTitle( title );
      setTrendingPosts( post_ids );
    });

    const posts = data.allPosts.nodes.map( node => {
      const { id, postId, data: { short_description, slug, title, date } } = node;

      return {
        id,
        postId,
        postShortDescription: short_description,
        postSlug: slug,
        postTitle: title,
        postDate: date,
        currentLang: defaultLang
      };
    });

    setAllPosts( posts );
  }, [data, defaultLang] );

  return (
    <TrendingElement className={ className }>
      <Title type="h2" text={ title } />
      <div className="trending__wrapper">
        { findingPosts.map( ( trending, index ) => {
          if ( width <= 480 && index <= mobile ) {
            return <TrendingPostPreview key={ index } data={ trending } />;
          } else if ( width > 480 && width <= 991 && index <= verticalTablet ) {
            return <TrendingPostPreview key={ index } data={ trending } />;
          } else if ( width <= 1024 && index <= horizontalTablet ) {
            return <TrendingPostPreview key={ index } data={ trending } />;
          } else if ( width <= 1280 && index <= desktop ) {
            return <TrendingPostPreview key={ index } data={ trending } />;
          }  else if ( width > 1280 && index <= desktop ) {
            return <TrendingPostPreview key={ index } data={ trending } />;
          } else return false;
        } ) }
      </div>
    </TrendingElement>
  )
});

Trending.defaultProps = {
  className: '',
  desktop: 1,
  horizontalTablet: 2,
  verticalTablet: 3,
  mobile: 2
};

Trending.propTypes = {
  className: PropTypes.string,
  desktop: PropTypes.number,
  horizontalTablet: PropTypes.number,
  verticalTablet: PropTypes.number,
  mobile: PropTypes.number
};

export default Trending;