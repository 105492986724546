import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { API_URL } from '../../../constants';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { LoadedPostsElement } from '../../../styles/elements/Post/LoadedPosts';

// import components
import LoadMoreBtn from './LoadMoreBtn';
import PostPreview from './PostPreview';

const LoadedPosts = memo( () => {
  const data = useStaticQuery( graphql`
    { 
      allApiPoints {
        nodes {
          categories_text {
            no_posts_load
          }
        }
      }
    }
  `);

  // get the global App state
  const state = useContext( GlobalStateContext );
  const { sponsoredAd, defaultLang, filteredPosts } = state;

  // define the default component state
  const alreadyLoaded = sponsoredAd ? 6 : 7;
  const [loadedPosts, setLoadedPosts]   = useState( alreadyLoaded );
  const [countLoads, setCountLoads]     = useState( 0 );
  const [posts, setPosts] = useState( [] );
  const [noPosts, setNoPosts] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    data.allApiPoints.nodes.forEach( node => {
      const { categories_text } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setNoPosts( categories_text[orderNumber].no_posts_load );
    });
  }, [data, defaultLang] );

  /**
   * Get posts by date
   */
  useEffect( () => {
    const url = `${ API_URL }blog_new/posts-sort-by-date-ascending/?lang=${ defaultLang }`;

    fetch( url )
      .then( response => response.json() )
      .then(
        data => {
          const { filter_results } = data;
          const posts = filter_results.map( post => {
            const {
              id, lang, short_description, slug, title, image_regular, image_regular_alt, image_regular_increased,
              image_regular_increased_alt, date, category, total_comments, comments_text_single, comments_text_multiple,
              author: { image, image_alt, name },
            } = post;

            return {
              id,
              postLang: lang,
              postShortDescription: short_description,
              postSlug: slug,
              postTitle: title,
              postRegularImage: image_regular,
              postRegularImageAlt: image_regular_alt,
              postRegularImageIncreased: image_regular_increased,
              postRegularImageIncreasedAlt: image_regular_increased_alt,
              postDate: date,
              postCategory: category,
              postAuthorData: { authorImg: image, image_alt, name },
              postCommentsCount: total_comments,
              postCommentsTextSingle: comments_text_single,
              postCommentsTextMultiple: comments_text_multiple,
              currentLang: defaultLang
            };
          });

          setPosts( posts );
        },
        error => { console.log( error ); }
      );
  }, [defaultLang] );

  /**
   * Show filtered posts
   */
  useEffect( () => {
    const filterPosts = filteredPosts.map( post => {
      const {
        id, short_description, slug, title, date, category, total_comments, image_regular, image_regular_alt,
        image_regular_increased, image_regular_increased_alt, comments_text_single, comments_text_multiple, lang,
        author: { image, image_alt, name }
      } = post;

      return {
        id,
        postLang: lang,
        postShortDescription: short_description,
        postSlug: slug,
        postTitle: title,
        postRegularImage: image_regular,
        postRegularImageAlt: image_regular_alt,
        postRegularImageIncreased: image_regular_increased,
        postRegularImageIncreasedAlt: image_regular_increased_alt,
        postDate: date,
        postCategory: category,
        postAuthorData: { authorImg: image, image_alt, name },
        postCommentsCount: total_comments,
        postCommentsTextSingle: comments_text_single,
        postCommentsTextMultiple: comments_text_multiple
      }
    });

    setPosts( filterPosts );
  }, [filteredPosts] );

  /**
   * Processing load more posts
   */
  const handleLoadPosts = () => {
    setLoadedPosts( posts => posts + 6 );
    setCountLoads( loads => loads + 1 );
  };

  if ( posts.length > ( alreadyLoaded + 1 ) ) {
    if ( countLoads > 0 ) {
      return(
        <LoadedPostsElement>
          <div className="container">
            <div className="loaded-post-wrapper">
              { posts.map( ( post, index ) => {
                const { id } = post;

                if ( index > alreadyLoaded && index <= loadedPosts ) {
                  return <PostPreview data={ post } key={ id } />;
                } else return false;
              })}
            </div>

            { loadedPosts >= posts.length -1 ?
              <p className="load__no-posts">{ noPosts }</p>
            :
              <LoadMoreBtn currentLang={ defaultLang } onClick={ handleLoadPosts } />
            }
          </div>
        </LoadedPostsElement>
      )
    } else {
      return(
        <LoadedPostsElement>
          <div className="container">
            <LoadMoreBtn currentLang={ defaultLang } onClick={ handleLoadPosts } />
          </div>
        </LoadedPostsElement>
      )
    }
  } else return false;
});

export default LoadedPosts;