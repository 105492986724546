import React, { useState, useContext, useEffect } from 'react';
import { useStaticQuery, graphql }                from 'gatsby';
import { animated, useSpring }                    from 'react-spring';

// import context
import { GlobalStateContext } from '../../context/GlobalContextProvider';

// import helpers
import { useWindowSize } from '../../helpers';

// import styles
import { MainElement } from '../../styles/BlogMain/Main';

// import components
import Title            from '../elements/Title';
import FeaturedSlider   from '../elements/FeaturedSlider';
import Search           from '../elements/Search';
import Trending         from '../elements/Trending';
import DecorativeArrows from '../elements/DecorativeArrows';

/**
 * Add mouse wheel animation to decorative arrows
 */
const FloatingArrows = () => {
  const [state, setState] = useState({
    xy: [0, 0],
    isMoved: false
  });

  const { width } = useWindowSize();
  const xy0 = state.xy[0];
  const xy1 = state.xy[1];
  let translateX = xy0 / 10 + 70;
  let translateY = xy1 / 10 - 20;

  if ( width <= 1280 ) {
    translateX = xy0 / 10 + 50;
    translateY = xy1 / 10 - 30;
  } else if ( width <= 1440 ) {
    translateX = xy0 / 10 + 55;
    translateY = xy1 / 10 - 20;
  } else {
    translateX = xy0 / 10 + 70;
    translateY = xy1 / 10 - 20;
  }


  const props = useSpring({
    to: { transform: state.isMoved ? `translate3d(${ translateX }px, ${ translateY }px, 0)` : `translate3d(0px, 0px, 0px)` },
    from: { transform: `translate3d(0px, 0px, 0px)` },
    config: { mass: 10, tension: 150, friction: 40 }
  });

  const calc = ( x, y ) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

  return <animated.div className="decorative-arrows"
                       style={ props }
                       role="presentation"
                       onMouseMove={ ( { clientX: x, clientY: y } ) => { setState( { xy: calc( x, y ), isMoved: true } ) } }>
      <DecorativeArrows className="decorative-arrows__element" />
    </animated.div>
};

const Main = () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          featured {
            title
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [title, setTitle] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { featured } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setTitle( featured[orderNumber].title );
    });
  }, [data, defaultLang] );

  return (
    <MainElement>
      <div className="container">
        <Title type="h2" text={ title } />
        <div className="grid">
          <FeaturedSlider />
          <aside className="right-column">
            <Search />
            <Trending desktop={ 1 } horizontalTablet={ 2 } verticalTablet={ 3 } mobile={ 2 } />
          </aside>
          <FloatingArrows />
        </div>
      </div>
    </MainElement>
  );
};

export default Main;