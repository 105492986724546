import React, { memo } from 'react';

const FeaturedSliderNavPrev = memo( () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="314.49"
         height="12.217"
         viewBox="0 0 314.49 12.217">
      <g transform="translate(313.49 16.512) rotate(180)">
        <path d="M4.879,6.106.256,1.487a.869.869,0,0,1,0-1.233.88.88,0,0,1,1.237,0L6.731,5.488a.871.871,0,0,1,.025,1.2L1.5,11.962A.873.873,0,0,1,.26,10.729Z"
              transform="translate(306.505 4.296)"
              fill="#d1d3d4"/>
        <line x1="312"
              transform="translate(0 9.984)"
              fill="none"
              stroke="#d1d3d4"
              strokeLinecap="round"
              strokeWidth="2"/>
      </g>
    </svg>
  )
});

export default FeaturedSliderNavPrev;