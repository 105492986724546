import styled from 'styled-components';

export const LoadedPostsElement = styled.section`
  .loaded-post-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    
    &::after {
      content: '';
      flex: auto;
    }
  }
  
  .load {
    &__no-posts {
      --decorative-width: 555px;
    
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      color: var(--theme-dark-gray);
      
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 25px;
        width: var(--decorative-width);
        height: 2px;
        background-color: var(--theme-gray);
      }
      
      &::before {
        left: 0;
      }
      
      &::after {
        right: 0;
      }
    }
  }
  
  @media (max-width: 1440px) {
    .load {
      &__no-posts {
        --decorative-width: 500px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .load {
      &__no-posts {
        --decorative-width: 420px;
      }
    }
  }
  
  @media (max-width: 1024px) {
    .load {
      &__no-posts {
        --decorative-width: 300px;
      }
    }
  }
  
  @media (max-width: 991px) {
    .load {
      &__no-posts {
        --decorative-width: 200px;
      }
    }
  }
  
  @media (max-width: 640px) {
    .load {
      &__no-posts {
        --decorative-width: 120px;
      }
    }
  }
  
  @media (max-width: 550px) {
    .load {
      &__no-posts {
        --decorative-width: 80px;
      }
    }
  }
  
   @media (max-width: 480px) {
    .load {
      &__no-posts {
       --decorative-width: 50px;
      }
    }
  }
`;