import styled from 'styled-components';

export const TitleElement = styled.div`
  .title {
    margin: 0 0 25px 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    .title {
      margin-bottom: 9px;
    }
  }
  
  @media (max-width: 991px) {
    .title {
      margin-bottom: 5px;
    }
    
    .category-title {
      margin: 30px 0;
      text-align: center;
      font-size: 18px;
      line-height: 21px;
      text-transform: initial;
    }
  }
  
  @media (max-width: 375px) {
    .category-title {
      margin-top: 10px;
    }
  }
`;