import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';
import { animated, useSpring }                          from 'react-spring';
import PropTypes                                        from 'prop-types';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useEmailValidation, useWindowSize } from '../../../helpers';

// import styles
import { NewsletterElement } from './styles';

// import components
import ArrowRegularIcon  from '../icons/ArrowRegularIcon';
import DecorativeCircles from '../DecorativeCircles';
import InputLoader       from '../InputLoader';

/**
 * Add mouse wheel animation to decorative circles
 */
const FloatingCircles = () => {
  const [state, setState] = useState({
    xy: [0, 0],
    isMoved: false
  });

  const { width } = useWindowSize();
  const xy0 = state.xy[0];
  const xy1 = state.xy[1];
  let translateX = xy0 / 10 - 65;
  let translateY = xy1 / 10 + 20;

  if ( width <= 1024 ) {
    translateY = xy1 / 10;
  } else if ( width <= 1280 ) {
    translateY = xy1 / 10 - 10;
  } else if ( width <= 1440 ) {
    translateY = xy1 / 10;
  } else {
    translateY = xy1 / 10 + 20;
  }

  const props = useSpring({
    to: { transform: state.isMoved ? `translate3d(${ translateX }px, ${ translateY }px, 0)` : `translate3d(0px, 0px, 0px)` },
    from: { transform: `translate3d(0px, 0px, 0px)` },
    config: { mass: 10, tension: 150, friction: 40 }
  });

  const calc = ( x, y ) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

  return <animated.div className="newsletter-decorative-circles"
                       style={ props }
                       role="presentation"
                       onMouseMove={ ( { clientX: x, clientY: y } ) => { setState( { xy: calc( x, y ), isMoved: true } ) } }>

      <DecorativeCircles />
  </animated.div>
};

const NewsletterWide = memo( ( { className } ) => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          newsletters {
            error_invalid_email
            placeholder
            placeholder_conflict_registration
            placeholder_successful_registration
            subscription_url
            text
            text_conflict_registration
            text_successful_registration
            title
            title_conflict_registration
            title_successful_registration
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the component state
  const [isLoading, setIsLoading] = useState( false );
  const [conflict, setConflict]   = useState( false );
  const [success, setSuccess]     = useState( false );
  const [email, setEmail] = useState( '' );
  const [error, setError] = useState( false );
  const [texts, setTexts] = useState({
    newsletterTitle: '',
    newsletterText: '',
    newsletterPlaceholder: '',
    newsletterSubscriptionUrl: '',
    newsletterTitleSuccessful: '',
    newsletterTextSuccessful: '',
    newsletterPlaceholderSuccessful: '',
    newsletterTitleConflict: '',
    newsletterTextConflict: '',
    newsletterPlaceholderConflict: '',
    newsletterError: ''
  });

  const {
    newsletterTitle, newsletterText, newsletterPlaceholder, newsletterSubscriptionUrl,
    newsletterTitleSuccessful, newsletterTextSuccessful, newsletterPlaceholderSuccessful,
    newsletterTitleConflict, newsletterTextConflict, newsletterPlaceholderConflict,
    newsletterError
  } = texts;

  // get the component custom hooks
  const validation = useEmailValidation( email );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { newsletters } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = newsletters[orderNumber];
      const {
        title, text, placeholder, subscription_url, title_successful_registration, text_successful_registration,
        placeholder_successful_registration, title_conflict_registration, text_conflict_registration,
        placeholder_conflict_registration, error_invalid_email
      } = current;

      setTexts( t => ({
        ...t,
        newsletterTitle: title,
        newsletterText: text,
        newsletterPlaceholder: placeholder,
        newsletterSubscriptionUrl: subscription_url,
        newsletterTitleSuccessful: title_successful_registration,
        newsletterTextSuccessful: text_successful_registration,
        newsletterPlaceholderSuccessful: placeholder_successful_registration,
        newsletterTitleConflict: title_conflict_registration,
        newsletterTextConflict: text_conflict_registration,
        newsletterPlaceholderConflict: placeholder_conflict_registration,
        newsletterError: error_invalid_email
      }));
    });
  }, [data, defaultLang] );

  /**
   * Processing change the field
   *
   * @param e | Event
   */
  const handleChange = ( e ) => {
    setEmail( e.currentTarget.value );
    setError( false );
  };

  /**
   * Processing subscribe to the news
   *
   * @param e | Event
   */
  async function handleClick ( e ) {
    e.preventDefault();

    // validate an email
    if ( email !== '' && validation ) {
      const data = { email };

      // show loading process
      setIsLoading( true );

      // make a request
      const response = await fetch( newsletterSubscriptionUrl, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify( data )
      });

      const res = await response;
      const { status } = res;

      // if entered email has already in the list, show a conflict message
      if ( status === 409 ) {
        setIsLoading( false );
        setConflict( true );
        setSuccess( false );
        setError( false );
        setEmail( '' );
      }

      // show a success message
      if ( status === 201 ) {
        setIsLoading( false );
        setSuccess( true );
        setConflict( false );
        setError( false );
        setEmail( '' );
      }
    } else {
      // show an error message
      setError( true );
    }
  }

  const title = success ? newsletterTitleSuccessful : newsletterTitle && conflict ? newsletterTitleConflict : newsletterTitle;
  const text  = success ? newsletterTextSuccessful : newsletterText && conflict ? newsletterTextConflict : newsletterText;
  const placeholder  = success ? newsletterPlaceholderSuccessful : newsletterPlaceholder && conflict ? newsletterPlaceholderConflict : newsletterPlaceholder;

  return (
    <div className="container--relative">
      <NewsletterElement className={ `wide ${ className }` }>
        <h3 className="title">{ title }</h3>
        <div className="content">
          <p className="text">{ text }</p>
          <form className={ isLoading ? 'field-group is-loading' : 'field-group' }
                onSubmit={ ( e ) => handleClick( e ) }>
            <input className="field"
                   type="text"
                   value={ email }
                   disabled={ success }
                   placeholder={ placeholder }
                   onChange={ ( e ) => handleChange( e ) }
                   onFocus={ () => setError( false ) } />
            <button className="field__btn"
                    disabled={ success }
                    aria-label="Subscribe"
                    onClick={ ( e ) => handleClick( e ) }>
              <ArrowRegularIcon className="field__btn__arrow"
                                color="#54B5EC" />
            </button>
            { error ? <span className="error">{ newsletterError }</span> : false }
            <InputLoader isLoading={ isLoading } />
          </form>
        </div>
      </NewsletterElement>
      <FloatingCircles />
    </div>
  )
});

NewsletterWide.defaultProps = {
  className: ''
};

NewsletterWide.propTypes = {
  className: PropTypes.string
};

export default NewsletterWide;