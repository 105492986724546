import styled from 'styled-components';

export const FilterItemElement = styled.div`
  position: relative;
  width: 182px;
  height: 23px;
  margin-right: 25px;
  
  &:last-child {
    margin-right: 0;
  }
  
  &:hover {
    .item {
      border-color: var(--theme-dark-purple);
      color: var(--white);
      background-color: var(--theme-dark-purple);
      box-shadow: 0 3px 5px rgba(var(--black--rgb), .16);
      cursor: pointer;
      
      .arrow {
        transform: rotate(.25turn);
        
        path {
          stroke: var(--white);
        }
      }
    }
    
    .dropdown {
      animation: filterDropDown 1s ease forwards;
      animation-delay: .15s;
    }
  }
  
  .item {
    position:relative;
    display: flex;
    align-items: center;
    width: 100%;
    border: 2px solid var(--theme-dark-purple);
    border-radius: 12px;
    padding-left: 17px;
    line-height: 19px;
    color: var(--theme-deep-purple);
    background-color: transparent;
    transition: .3s all ease-in-out;
    box-shadow: 0 3px 5px rgba(var(--black--rgb), 0);
    z-index: 2;
    
    &--is-active {
      border-color: var(--theme-dark-purple);
      color: var(--white);
      background-color: var(--theme-dark-purple);
      box-shadow: 0 3px 5px rgba(var(--black--rgb), .16);
      
      .arrow {
        path {
          stroke: var(--white);
        }
      }
    }
    
    &.clear-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      opacity: 1;
      visibility: visible;
      border-color: transparent;
      border-bottom-color: #778186;
      border-radius: 12px 12px 0 0;
      padding-right: 10px;
      background-color: transparent;
      color: #778186;
      
       &:hover {
          box-shadow: none;
        }
      
      &--inactive {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  
  .clear-filter {
    &__icon {
     --dimensions: 10px;
     
     width: var(--dimensions);
     height: var(--dimensions);
    }
  }
  
  .arrow {
    position: absolute;
    top: 3px;
    right: 9px;
    width: 14px;
    height: 14px;
    z-index: 2;
    transition: .3s all ease-in-out;
    
    path {
      transition: .3s all ease-in-out;
    }
  }
  
  .dropdown {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 0;
    margin: 0;
    opacity: 0;
    visibility: hidden;
    padding: 36px 17px 15px 17px;
    border-radius: 12px;
    list-style: none;
    background-color: var(--theme-dark-purple);
    z-index: 1;
    transition: .15s all ease-out;
  
    &__item {
      margin-bottom: 10px;
      color: var(--white);
      transition: .3s all ease-in-out;
      
      &:last-child {
        margin-bottom: 0;
      }
      
      &:hover {
        margin-left: 5px;
        color: var(--theme-blue);
        cursor: pointer;
      }
      
      &--default {
        color: var(--theme-blue);
      }
     
      &__input {
        &:checked {
          & + label {
            color: var(--theme-blue);
          }
        }
      }
      
      &__label {
        display: block;
        color: var(--white);
        transition: .3s all ease-in-out;
        
        &:hover {
          margin-left: 5px;
          color: var(--theme-blue);
          cursor: pointer;
        }
      }
    }
  }
  
  /*-------------------------
  # filterDropDown
  --------------------------*/
  @keyframes filterDropDown {
    0% {
      max-height: 0;
      opacity: 0;
      visibility: hidden;
    }
    
    100% {
      max-height: 500px;
      opacity: 1;
      visibility: visible;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    width: 165px;
  }
  
  @media (max-width: 1024px) {
    width: 150px;
    margin-right: 15px;
  }
`;