import styled from 'styled-components';

export const FeaturedSliderElement = styled.div`
  --shifting: 40px;
  --post-width: 885px;

  position: relative;
  width: calc(var(--post-width) + var(--shifting) * 2);
  
  .featured-posts {
    position: relative;
    top: calc(var(--shifting) * -1);
    left: calc(var(--shifting) * -1);
    width: 100%;
    padding: var(--shifting) var(--shifting) 0 var(--shifting);
    
    &__nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 15px;
      
      &__item {
        display: flex;
        align-items: center;
        width: 315px;
        height: 14px;
        
        &:hover {
          cursor: pointer;
          
          svg {
            path {
              fill: var(--theme-blue);
            }
            
            line {
              stroke: var(--theme-blue);
            }
          }
        }
        
        svg {
          path,
          line {
            transition: .3s all ease-in-out;
          }
        }
      }
      
      &__counter {
        --counter-width: 115px;
      
        position: absolute;
        left: calc( ( var(--post-width) / 2 ) - ( var(--counter-width) / 2 ) );
        bottom: 34px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: var(--counter-width);
        font-size: 14px;
        line-height: 25px;
        letter-spacing: 1.2px;
        z-index: 1;
        
        &__current {
          color: var(--theme-blue);
        }
        
        &__separator {
          width: 48px;
          height: 2px;
          background-color: var(--theme-gray);
        }
        
        &__total {
          color: var(--theme-dark-gray);
        }
      }
    }
    
    .swiper {
      &-pagination {
        display: none;
      }
    }
  }
  
  .post {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 12px;
    padding: 34px 30px 22px 30px;
    background-color: var(--white);
    box-shadow: 0 0 30px rgba(var(--black--rgb), .2);
    
    &:hover {
      cursor: grab;
    }
    
    &__img {
      display: block;
      width: 100%;
      transition: 1s transform ease-in-out;
      
      &:hover {
        transform: scale(1.05);
      }
    }
    
    &__top,
    &__bottom {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
    }
    
    &__date {
      font-size: 14px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 1.1px;
      color: var(--theme-dark-gray);
    }
    
    &__actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      
      &__plus,
      &__like,
      &__share {
        width: 16px;
        height: 16px;
        margin-left: 5px;
        margin-right: 5px;
        
        &:hover {
          cursor: pointer;
        }
      }
      
      &__share {
        --dimensions: 40px;
        
        width: var(--dimensions);
        height: var(--dimensions);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        border-radius: 50%;
        background-color: transparent;
        transition: .3s all ease-in-out;
      
        &--is-active {
          background-color: var(--theme-dark-purple);
          
          svg {
            path {
              fill: var(--white);
            }
          }
        }
        
        &__icon {
          height: 20px;
          transition: .3s all ease-in-out;
        }
        
        &__wrapper {
          position: relative;
          margin-right: 0;
        }
      }
    }
    
    &__category {
      margin-bottom: 6px;
      font-size: 14px;
      line-height: 25px;
      letter-spacing: 1.15px;
      color: var(--theme-blue);
    }
    
    &__title {
      margin: 0 0 10px 0;
      
      &__link {
        display: block;
        font-size: 28px;
        font-weight: 700;
        line-height: 34px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1.25px;
        color: var(--theme-deep-purple);
        transition: .3s all ease-in-out;
        
        &:hover {
          color: var(--theme-blue);
        }
      }
    }
    
    &__short-description {
      margin: 0;
      font-weight: 400;
      letter-spacing: 1px;
    }
    
    &__author {
      display: flex;
      align-items: flex-start;
      width: 100%;
      border-top: 1px solid #707070;
      padding-top: 10px;
      
      &__img {
        --dimensions: 70px;
        
        width: var(--dimensions);
        height: var(--dimensions);
        margin-right: 20px;
        border-radius: 50%;
      }
      
      &__data {
        display: flex;
        flex-direction: column;
        margin-bottom: 36px;
      }
      
      &__name {
        font-weight: 400;
        letter-spacing: 1.1px;
        color: var(--theme-dark-gray);
      }
      
      &__comments {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 1.1px;
        color: var(--theme-dark-gray);
        
        &__separator {
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
    
    &__read-more {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 57px;
      border: 2px solid var(--theme-blue);
      border-radius: 36px;
      font-size: 16px;
      line-height: 18px;
      font-weight: 500;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.2px;
      color: var(--theme-blue);
      background-image: linear-gradient(45deg, var(--theme-blue) 50%, transparent 50%);
      background-position: 100%;
      background-size: 400%;
      transition: all .5s ease-in-out;
      
      &:hover {
        border-color: var(--theme--dark-blue);
        color: var(--white);
        background-position: 0;
        cursor: pointer;
      }
    }
  }
  
  .left-column {
    min-width: 418px;
    margin-right: 66px;
    overflow: hidden;
  }
  
  .right-column {
    width: 100%;
    
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    --shifting: 25px;
    --post-width: 795px;
    
    .featured-posts {
      &__nav {
        &__counter {
          bottom: 19px;
        }
      }
    }
  
    .post {
      padding: 15px 20px;
      
      &__header {
        margin-bottom: 10px;
      }
      
      &__category {
        margin-bottom: 4px;
      }
      
      &__short-description {
        line-height: 27px;
      }
      
      &__author {
        &__img {
          --dimensions: 65px;
          margin-right: 10px;
        }
        
        &__comments {
          line-height: 25px;
        }
        
        &__data {
          margin-bottom: 22px;
        }
      }
      
      &__actions {
        &__share {
          &__wrapper {
            .share-box {
              top: inherit;
              bottom: 0;
              
              &::after {
                bottom: inherit;
                top: -10px;
                transform: rotate(.5turn);
              }
              
              &.is-visible {
                bottom: -75px;
              }
            }
          }
        }
      }
    }
    
    .left-column {
      min-width: 365px;
      margin-right: 30px;
    }
  }
  
  @media (max-width: 1280px) {
    --post-width: 694px;
  
    .post {
      padding: 11px 22px;
      
      &__header,
      &__category,
      &__title {
        margin-bottom: 0;
      }
      
      &__title {
        &__link {
          font-size: 24px;
          line-height: 28px;
        }
      }
      
      &__short-description {
        margin-bottom: 5px;
        line-height: 24px;
      }
      
      &__author {
        &__img {
          display: none;
        }
        
        &__data {
          display: flex;
          flex-wrap: nowrap;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 5px;
        }
        
        &__comments {
          display: flex;
          flex-direction: column;
          line-height: 19px;
          
          &__string {
            text-align: right;
          }
          
          &__separator {
            display: none;
          }
        }
      }
    }
    
    .left-column {
      min-width: 305px;
      margin-right: 25px;
    }
    
    .featured-posts {
      &__nav {
        margin-top: 10px;
      
        &__item {
          width: 260px;
        }
      }
    }
  }
  
  @media (max-width: 1024px) {
    --post-width: 550px;
    
    .left-column {
      min-width: 235px;
      margin-right: 15px;
    }
    
    .post {
      padding: 12px;
      
      &__date {
        line-height: 20px;
      }
      
      &__title {
        &__link {
          font-size: 20px;
          line-height: 24px; 
        }
      }
      
      &__short-description {
        font-size: 14px;
        line-height: 18px;
      }
      
      &__author {
        &__name {
          font-size: 14px;
          line-height: 18px;
        }
        
        &__comments {
          line-height: 16px;
        }
      }
      
      &__read-more {
        width: 160px;
        height: 45px;
      }
      
      &__actions {
        &__share {
          &__wrapper {
            .share-box {
              &.is-visible {
                bottom: -60px;
              }
            }
          }
        }
      }
    }
    
    .featured-posts {
      &__nav {
        &__item {
          width: 200px;
        }
      }
    }
  }
  
  @media (max-width: 991px) {
    --post-width: 100%;
    --shifting: 15px;
    
    .featured-posts {
      padding: var(--shifting) var(--shifting) 30px var(--shifting);
    
      &__nav {
        display: none;
        
        &__counter {
          display: none;
        }
      }
      
      .swiper {
        &-pagination {
          --dimensions: 10px;
        
          position: absolute;
          left: 0;
          bottom: 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: var(--dimensions);
          
          &-bullet {
            width: var(--dimensions);;
            height: var(--dimensions);;
            margin: 0 5px;
            border-radius: 50%;
            background-color: var(--theme-gray);
            transition: .3s all ease-in-out;
            
            &-active {
              background-color: var(--theme-blue);
            }
          }
        }
      }
    }
    
    .post {
      box-shadow: 0 0 20px rgba(var(--black--rgb),.2);
      
      &__date {
        line-height: 25px;
      }
      
      &__title {
        margin-bottom: 5px;
        font-size: 24px;
        font-weight: 600;
        line-height: 28px;
      }
      
      &__short-description {
        font-size: 16px;
        line-height: 24px;
      }
      
      &__author {
        margin-bottom: 10px;
      
        &__img {
          --dimensions: 55px;
        
          display: block;
        }
        
        &__data {
          flex-wrap: wrap;
          flex-direction: column;
        }
        
        &__name {
          font-size: 18px;
          line-height: 29px;
        }
        
        &__comments {
          flex-direction: row;
          
          &__separator {
            display: block;
          }
        }
      }
    }
    
    .left-column {
      min-width: 310px;
    }
  }
  
  @media (max-width: 767px) {
    .left-column {
      min-width: 270px;
    }
    
    .post {
      &__title {
        font-size: 20px;
        line-height: 24px;
      }
      
      &__short-description {
        font-size: 14px;
        line-height: 20px;
      }
      
      &__author {
        &__img {
          display: none;
        }
        
        &__name {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
  
  @media (max-width: 640px) {
    .left-column {
      min-width: 250px;
    }
  }
  
  @media (max-width: 550px) {
    .left-column {
      min-width: 190px;
    }
  }
  
  @media (max-width: 480px) {
    --shifting: 50px;
  
    width: var(--post-width);
    
    .featured-posts {
      top: inherit;
      left: inherit;
      padding: 20px var(--shifting) var(--shifting) var(--shifting);
      
      .swiper {
        &-pagination {
          bottom: 20px;
        }
      }
    }
    
    .post {
      flex-direction: column;
      margin: 0 5px;
      padding: 15px;
      transition: all 0.3s ease-in-out 0s;
      
      &__img {
        width: 100%;
      }
      
      &__header {
        margin-bottom: 10px;
      }
      
      &__title {
        font-size: 20px;
        line-height: 22px;
      }
      
      &__short-description {
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 20px;
      }
      
      &__author {
        &__data {
          flex-direction: row;
          align-items: center;
        }
        
        &__name,
        &__comments {
          font-size: 14px;
          line-height: 20px;
        }
      }
      
      &__bottom {
        &__actions {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      
      &__actions {
        &__item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 45px;
          height: 45px;
          margin-right: 5px;
          border: 2px solid var(--theme-deep-purple);
          border-radius: 50%;
          
          svg {
            width: 25px;
            height: 25px;
          }
        }
        
        &__share {
          --dimensions: 45px;
          
          &__wrapper {
            .share-box {
              top: 0;
              bottom: initial;
              left: -7px;
              
              &::after {
                top: initial;
                bottom: -10px;
                transform: none;
              }
            
              &.is-visible {
                top: -60px;
                
              }
            }
            
            .share-item {
              --dimensions: 30px;
              
              svg {
                width: 65%;
                height: 65%;
              }
            }
          }
        }
        
        &__plus,
        &__like,
        &__share {
          margin: 0;
        }
      }
      
      &__img,
      &__category,
      &__date,
      &__title,
      &__short-description,
      &__bottom {
        opacity: 0;
        visibility: hidden;
      }
      
      &.swiper {
        &-slide {
          transform: scale(.95);
          
          &-next,
          &-prev {
            height: 500px;
          }
        
          &-active {
            transform: scale(1);
            
            .post {
              &__img,
              &__category,
              &__date,
              &__title,
              &__short-description,
              &__bottom {
                opacity: 1;
                visibility: visible;
              }
            }
            
            .skeleton {
              opacity: 0;
              visibility: hidden;
            }
          }
        }
      }
    }
    
    .left-column {
      min-width: 100%;
      margin-bottom: 10px;
    }
    
    .skeleton {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0s;
    }
  }
  
  @media (max-width: 390px) {
    --shifting: 36px;
  
    .post {
      &__bottom {
        &__actions {
          flex-wrap: wrap;
        }
      }
      
      &__title {
        margin-bottom: 15px;
      }
      
      &__short-description {
        display: none;
      }
      
      &__actions {
        &__item {
          width: 45px;
          height: 45px;
        }
      }
      
      &.swiper {
        &-slide {
          &-next,
          &-prev {
            height: 330px;
          }
        }
      }
    }
    
    .featured-posts {
      .swiper-pagination {
        bottom: 10px;
      }
    }
  }
  
  @media (max-width: 375px) {
    .skeleton {
      transform: scale(.97);
    }
  }
`;