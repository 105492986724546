import React from 'react';

// import styles
import { PostsGridElement } from '../../styles/BlogMain/PostsGrid';

// import components
import PostsList from '../elements/Post/PostsList';

const PostsGrid = () => {
  return (
    <PostsGridElement>
      <div className="container">
        <PostsList />
      </div>
    </PostsGridElement>
  )
};
export default PostsGrid;
