import styled from 'styled-components';

export const TrendingPostPreviewElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  
  .date {
    margin: 0 0 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 1.1px;
    color: var(--theme-dark-gray);
  }
  
  .title {
    position: relative;
    margin: 0 0 10px 0;
    
    &__link {
      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 1.25px;  
      color: var(--theme-deep-purple);
      transition: .3s all ease-in-out;
      
      &:hover {
        color: var(--theme-blue);
      }
    }
  }
  
  .description {
    margin: 0;
    letter-spacing: 1.1px;
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    margin-bottom: 10px;
  
    .date {
      margin-bottom: 5px;
    }
    
    .title {
      margin-bottom: 3px;
    }
    
    .description {
      line-height: 25px;
    }
  }
  
  @media (max-width: 1280px) {
    .title {
      line-height: 18px;
    }
    
    .description {
      line-height: 22px;
    }
  }
  
  @media (max-width: 1024px) {
    .title {
      font-size: 16px;
      line-height: 20px;
    }
    
    .description {
      font-size: 14px;
      line-height: 20px;
    }
  }
  
  @media (max-width: 991px) {
    width: 48%;
    
    .title {
      font-size: 18px;
      line-height: 21px;
    }
    
    .description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  
  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--theme-gray);
    
    &:last-child {
      border-bottom: none;
    }
    
    .description {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;