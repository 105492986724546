import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes                                        from 'prop-types';

// import constants
import { CHANGE_FILTER, CHANGE_SORT_TITLE, GET_FILTERED_POSTS } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { FilterItemElement } from './styles/FilterItem';

// import components
import ArrowIcon from '../icons/ArrowIcon';

const FilterItem = memo( ( { title, values, isDefault, filterType } ) => {
  // get the app global state
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { filterRequestData, sortDropdownTitle } = state;

  // define the default component state
  const [currentTitle, setCurrentTitle] = useState( '' );
  const [isActive, setIsActive] = useState( false );
  const [filterValues, setFilterValues] = useState({
    authorValues: [],
    yearValues: [],
    timeValues: [],
    levelValues: []
  });

  /**
   * Set title for the sort dropdown when it was changed
   */
  useEffect( () => {
    setCurrentTitle( title );
  }, [title] );

  /**
   * Set the default request data
   */
  useEffect( () => {
    if ( isDefault ) {
      const data = {
        author: '',
        year: '',
        time: '',
        level: '',
        sort: values.length > 0 ? values[0].value : ''
      };

      dispatch( { type: CHANGE_FILTER, payload: data } );
    }
  }, [isDefault, values, dispatch] );

  /**
   * Clear filters
   */
  useEffect( () => {
    const { author, year, time, level } = filterRequestData;
    if (
      author === '' &&
      year === '' &&
      time === '' &&
      level === ''
    ) {
      setIsActive( false );
    }
  }, [filterRequestData] );

  /**
   * Processing a click on the filter element
   *
   * @param value | string
   * @param type | string
   * @param title | string
   */
  const handleClick = ( value, type, title ) => {
    const { author, year, time, level, sort } = filterRequestData;
    //const { authorValues, yearValues } = filterValues;

    if ( filterType === 'sort' ) {
      dispatch( { type: CHANGE_SORT_TITLE, payload: title } );
    }
/*
    // filtering by Author
    if ( type === 'author' ) {
      // add the author value if it doesn't exist in the authors array
      if ( authorValues.indexOf( value ) === -1 ) {
        setFilterValues( f => ({
            ...f,
            authorValues: [
              ...f.authorValues,
              value
            ]
          })
        )

      // remove the author value if it exists in the authors array
      } else {
        const removeAuthor = authorValues.filter( authorValue => authorValue !== value );

        setFilterValues(
          {
            ...filterValues,
            authorValues: removeAuthor
          }
        )
      }
    }

    // filtering by Year
    if ( type === 'year' ) {
      // add the year value if it doesn't exist in the years array
      if ( yearValues.indexOf( value ) === -1 ) {
        setFilterValues( f => ({
            ...f,
            yearValues: [
              ...f.yearValues,
              value
            ]
          })
        )

        // remove the year value if it exists in the year array
      } else {
        const removeYear = yearValues.filter( yearValue => yearValue !== value );

        setFilterValues(
          {
            ...filterValues,
            yearValues: removeYear
          }
        )
      }
    }*/





    if (
      ( type === 'author' && value === author ) ||
      ( type === 'year' && value === year ) ||
      ( type === 'time' && value === time ) ||
      ( type === 'level' && value === level )
    ) {
      const string  = sort + author + year + time + level;
      const request = string.replace( value, '' );

      fetch( request )
        .then( response => response.json() )
        .then( data => {
          const { filter_results } = data;
          dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, [`${ type }`]: '' } } );
          dispatch( { type: GET_FILTERED_POSTS, payload: filter_results } );
          setIsActive( false );
        });
    } else if ( type === 'sort' && value === sort ) {
      return false;
    } else {
      if ( type === 'author' ) {
        setFilterValues(
          {
            ...filterValues,
            authorValues: [
              ...filterValues.authorValues,
              value
            ]
          }
        )
      }

      const request = type === 'sort' ? value + author + year + time + level : sort + author + year + time + level + value;

      fetch( request )
        .then( response => response.json() )
        .then( data => {
          const { filter_results } = data;
          dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, [`${ type }`]: value } } );
          dispatch( { type: GET_FILTERED_POSTS, payload: filter_results } );
          setIsActive( true );
        });
    }
  };

  /*const handleChange = ( e, type ) => {
    const _this = e.currentTarget;
    const { value, checked } = _this;
    const { author, year, sort } = filterRequestData;
    const preparedAuthor = author.join( '' );
    const preparedYear = year.join( '' );

    if ( checked ) {
      if ( type === 'author' ) {
        const authorData = [...author, value];

        dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, author: authorData } } );
      }

      if ( type === 'year' ) {
        const yearData = [...year, value];

        dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, year: yearData } } );
      }
    } else {
      if ( type === 'author' ) {
        const removeAuthor = author.filter( authorValue => authorValue !== value );

        dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, author: removeAuthor } } );
      }

      if ( type === 'year' ) {
        const removeYear = year.filter( yearValue => yearValue !== value );

        dispatch( { type: CHANGE_FILTER, payload: { ...filterRequestData, year: removeYear } } );
      }
    }

    const request = sort + preparedAuthor + preparedYear;

    fetch( request )
      .then( response => response.json() )
      .then( data => {
        const { filter_results } = data;
        dispatch( { type: GET_FILTERED_POSTS, payload: filter_results } );
        setIsActive( true );
      });
  };*/

  return (
    <FilterItemElement>
      <span className={ isActive ? 'item item--is-active' : 'item' }>
        { filterType === "sort" ? sortDropdownTitle : currentTitle }
        <ArrowIcon className="arrow" direction="down" color="#431E75" />
      </span>
      {/*<div className="dropdown">
        { values.map( item => {
          const { title, value, type } = item;

          return <div key={ value }
                      className="dropdown__item">
            <input className="dropdown__item__input"
                   id={ value }
                   type="checkbox"
                   value={ value }
                   hidden={ true }
                   onChange={ ( e ) => handleChange( e, type ) } />
            <label className="dropdown__item__label" htmlFor={ value }>{ title }</label>
          </div>;

          return <li className={ filterRequestData[`${ type }`] === value ? 'dropdown__item dropdown__item--default' : 'dropdown__item' }
                     key={ value }
                     role="presentation"
                     onClick={ () => handleClick( value, type, title ) }>{ title }</li>
        })}
      </div>*/}

      <ul className="dropdown">
        { values.map( item => {
          const { title, value, type } = item;

          return <li className={ filterRequestData[`${ type }`] === value ? 'dropdown__item dropdown__item--default' : 'dropdown__item' }
                     key={ value }
                     role="presentation"
                     onClick={ () => handleClick( value, type, title ) }>{ title }</li>
        })}
      </ul>
    </FilterItemElement>
  )
});

FilterItem.defaultProps = {
  title: '',
  values: [],
  isDefault: false,
  filterType: 'filter', // available: filter, sort
  index: 1
};

FilterItem.propTypes = {
  title: PropTypes.string,
  values: PropTypes.array,
  isDefault: PropTypes.bool,
  filterType: PropTypes.string,
  index: PropTypes.number
};

export default FilterItem;