import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { API_URL } from '../../../constants';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { PostsListElement }        from '../../../styles/elements/Post/PostsList';
import { PostListNoResultElement } from '../../../styles/elements/Post/PostListNoResults';

// import components
import PostPreview    from './PostPreview';
import SponsoredAd    from '../Ad/SponsoredAd';
import NewsletterWide from '../Newsletter/NewsletterWide';

const PostsList = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          sort_filters {
            no_results
          }
        }
      }
    }
  `);

  // get the global App state
  const state = useContext( GlobalStateContext );
  const { sponsoredAd, defaultLang, filteredPosts } = state;

  // define the default component state
  const [posts, setPosts] = useState( [] );
  const [noResults, setNoResults] = useState( '' );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { sort_filters } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setNoResults( sort_filters[orderNumber].no_results );
    });
  }, [data, defaultLang] );

  /**
   * Get posts by date
   */
  useEffect( () => {
    const url = `${ API_URL }blog_new/posts-sort-by-date-ascending/?lang=${ defaultLang }`;

    fetch( url )
      .then( response => response.json() )
      .then(
        data => {
          const { filter_results } = data;
          const posts = filter_results.map( post => {
            const {
              id, lang, short_description, slug, title, image_regular, image_regular_alt, image_regular_increased,
              image_regular_increased_alt, date, category, total_comments, comments_text_single, comments_text_multiple,
              author: { image, image_alt, name },
            } = post;

            return {
              id,
              postLang: lang,
              postShortDescription: short_description,
              postSlug: slug,
              postTitle: title,
              postRegularImage: image_regular,
              postRegularImageAlt: image_regular_alt,
              postRegularImageIncreased: image_regular_increased,
              postRegularImageIncreasedAlt: image_regular_increased_alt,
              postDate: date,
              postCategory: category,
              postAuthorData: { authorImg: image, image_alt, name },
              postCommentsCount: total_comments,
              postCommentsTextSingle: comments_text_single,
              postCommentsTextMultiple: comments_text_multiple,
              currentLang: defaultLang
            };
          });

          setPosts( posts );
        },
        error => { console.log( error ); }
      );
  }, [defaultLang] );

  /**
   * Show filtered posts
   */
  useEffect( () => {
    const filterPosts = filteredPosts.map( post => {
      const {
        id, short_description, slug, title, date, category, total_comments, image_regular, image_regular_alt,
        image_regular_increased, image_regular_increased_alt, comments_text_single, comments_text_multiple, lang,
        author: { image, image_alt, name }
      } = post;

      return {
        id,
        postLang: lang,
        postShortDescription: short_description,
        postSlug: slug,
        postTitle: title,
        postRegularImage: image_regular,
        postRegularImageAlt: image_regular_alt,
        postRegularImageIncreased: image_regular_increased,
        postRegularImageIncreasedAlt: image_regular_increased_alt,
        postDate: date,
        postCategory: category,
        postAuthorData: { authorImg: image, image_alt, name },
        postCommentsCount: total_comments,
        postCommentsTextSingle: comments_text_single,
        postCommentsTextMultiple: comments_text_multiple,
        currentLang: defaultLang
      }
    });

    setPosts( filterPosts );
  }, [filteredPosts, defaultLang] );

  /**
   * Make render
   */
  return (
    posts.length > 0 ?
      <>
        <PostsListElement>
          { posts.map( ( post, index ) => {
            const { id } = post;

            if ( index < 3 ) {
              return <PostPreview data={ post } key={ id } />;
            } else return false;
          } ) }
        </PostsListElement>

        <NewsletterWide />

        <PostsListElement>
          { posts.map( ( post, index ) => {
            const { id } = post;

            // check if the advertisement banner is enable
            if ( sponsoredAd ) {
              // if the advertisement banner is enable, show first 7 posts
              if ( index > 2 && index <= 6 ) {
                // if the advertisement banner is enable, after the 4th post show the advertisement banner
                if ( index === 3 ) {
                  return (
                    <React.Fragment key={ id }>
                      <PostPreview data={ post } />
                      <SponsoredAd additionalClass="grid-ad" />
                    </React.Fragment>
                  )

                  // add special class for both last posts
                } else if ( index === 5 || index === 6 ) {
                  return <PostPreview data={ post }
                                      additionalClass="half"
                                      key={ id } />;

                  // show the regular post
                } else {
                  return <PostPreview data={ post } key={ id } />;
                }
              } else return false;

              // if the advertisement banner is disable, show the first 8 posts
            } else {
              if ( index > 2 && index <= 7 ) {
                // add special class for both last posts
                if ( index === 6 || index === 7 ) {
                  return <PostPreview data={ post }
                                      additionalClass="half"
                                      key={ id } />;

                  // show the regular post
                } else {
                  return <PostPreview data={ post } key={ id } />;
                }
              } else return false;
            }
          } ) }
        </PostsListElement>
      </>
    :
      <>
        <NewsletterWide />
        <PostListNoResultElement>
          <h3 className="no-results">{ noResults }</h3>
        </PostListNoResultElement>
      </>
  )
});

export default PostsList;