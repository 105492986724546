import styled from 'styled-components';

export const MainElement = styled.section`
  margin-bottom: 30px;
  
  .grid {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .right-column {
    width: 100%;
  }
  
  .decorative-arrows {
    position: absolute;
    left: -85px;
    bottom: 95px;
    
    &__element {
      path {
        &:nth-child(1) {
          animation: 3s ease infinite alternate arrowSwitchColorToPurple;
          animation-delay: .3ms;
        }
        
        &:nth-child(2) {
          animation: 3s ease infinite alternate arrowSwitchColorToBlue;
          animation-delay: .2ms;
        }
        
        &:nth-child(3) {
          animation: 3s ease infinite alternate arrowSwitchColorToPurple;
          animation-delay: .1ms;
        }
        
        &:nth-child(4) {
          animation: 3s ease infinite alternate arrowSwitchColorToBlue;
        }
      }
    }
  }
  
  /*-------------------------
  # arrowSwitchColorToPurple
  --------------------------*/
  @keyframes arrowSwitchColorToPurple {
    0% {
      fill: #00b7f1;
    }
    
    50% {
      fill: #543a8e;
    }
    
    100% {
      fill: #00b7f1;
    }
  }
  
  /*-------------------------
  # arrowSwitchColorToBlue
  --------------------------*/
  @keyframes arrowSwitchColorToBlue {
    0% {
      fill: #543a8e;
    }
    
    50% {
      fill: #00b7f1;
    }
    
    100% {
      fill: #543a8e;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1440px) {
    .decorative-arrows {
      left: -55px;
      bottom: 75px;
    
      &__element {
        width: 84px;
        height: 84px;
      }
    }
  }
  
  @media (max-width: 1280px) {
    .decorative-arrows {
      left: -40px;
      bottom: 70px;
    }
  }
  
  @media (max-width: 1024px) {
    .decorative-arrows {
      left: -30px;
      bottom: 50px;
      
      &__element {
        width: 62px;
        height: 62px;
      }
    }
  }
  
  @media (max-width: 991px) {
    .grid {
      flex-wrap: wrap;
    }
    
    .decorative-arrows {
      top: 360px;
      bottom: inherit;
    }
  }
  
  @media (max-width: 640px) {
    .decorative-arrows {
      top: 280px;
    }
  }
  
  @media (max-width: 480px) {
    .container {
      padding: 0;
    }
  
    .title {
      display: none;
    }
    
    .decorative-arrows {
      top: 500px;
      left: 10px;
    }
  }
  
  @media (max-width: 390px) {
    .decorative-arrows {
      top: 380px;
      left: 0;
    }
  }
`;