import React, { memo } from 'react';

const DecorativeCircles = memo( () => {
  return (
    <>
      <svg className="decorative-circle decorative-circle__outer"
           xmlns="http://www.w3.org/2000/svg"
           width="124.672"
           height="124.674"
           viewBox="0 0 124.672 124.674">
        <path d="M559.855,802.72a62.407,62.407,0,0,1-62.336-62.338,1.447,1.447,0,0,1,2.893,0,59.443,59.443,0,1,0,59.443-59.443,1.447,1.447,0,1,1,0-2.893,62.337,62.337,0,0,1,0,124.674Z"
              transform="translate(-497.519 -678.046)"
              fill="#00b7f1" />
      </svg>

      <svg className="decorative-circle decorative-circle__middle"
           xmlns="http://www.w3.org/2000/svg"
           width="76.909"
           height="76.91"
           viewBox="0 0 76.909 76.91">
        <path d="M552.484,771.468a38.455,38.455,0,0,1,0-76.91,2.17,2.17,0,0,1,0,4.339A34.115,34.115,0,1,0,586.6,733.01a2.17,2.17,0,0,1,4.339,0A38.5,38.5,0,0,1,552.484,771.468Z"
              transform="translate(-514.029 -694.558)"
              fill="#291663" />
      </svg>

      <svg className="decorative-circle decorative-circle__inner"
           xmlns="http://www.w3.org/2000/svg"
           width="26.719"
           height="26.718"
           viewBox="0 0 26.719 26.718">
        <path d="M544.738,738.624a13.359,13.359,0,1,1,13.36-13.36A13.374,13.374,0,0,1,544.738,738.624Zm0-22.379a9.02,9.02,0,1,0,9.02,9.019A9.028,9.028,0,0,0,544.738,716.245Z"
              transform="translate(-531.378 -711.906)"
              fill="#543a8e" />
      </svg>
    </>
  )
});

export default DecorativeCircles;