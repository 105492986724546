import styled from 'styled-components';

export const SponsoredAdElement = styled.div`
  --ad-mr: 40px;

  position: relative;
  height: 680px;
  border-top: 1px solid var(--theme-blue);
  border-bottom: 1px solid var(--theme-blue);
  padding: 20px 0;
  
  &.grid-ad {
    width: calc(1 / 3 * 100% - (1 - 1 / 3) * var(--ad-mr));
    margin: 0 var(--ad-mr) 0 0;
  }
  
  .overflow-title {
    position: absolute;
    top: -30px;
    left: 0;
    letter-spacing: 1.2px;
    color: var(--theme-blue);
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    padding: 20px;
    background: linear-gradient(180deg, #00ACEF, #291663);
  }
  
  .ad-logo {
    width: 105px;
    margin-bottom: 30px;
  }
  
  .title {
    margin: 0;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 41px;
    text-transform: uppercase;
    letter-spacing: 1.25px;
    color: var(--white);
  }
  
  .text {
    margin: 0;
    padding: 0 40px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 1.5px;
    color: var(--white);
  }
  
  .price {
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: 41px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: var(--white);
  }
  
  .link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 57px;
    border: 2px solid var(--white);
    border-radius: 38px;
    padding: 0;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 1.25px;
    color: var(--theme-blue);
    background-color: var(--white);
    background-image: linear-gradient(45deg, var(--theme-blue) 50%, transparent 50%);
    background-position: 100%;
    background-size: 400%;
    transition: all .5s ease-in-out;
    
    &:hover {
      color: var(--white);
      background-position: 0;
      cursor: pointer;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    --ad-mr: 20px;
    
    height: 652px;
  
    &.grid-ad {
      width: 402px;
    }
    
    .text {
      padding: 0 35px;
      line-height: 27px;
    }
  }
  
  @media (max-width: 1280px) {
    --ad-mr: 15px;
  
    height: 596px;
  
    &.grid-ad {
      width: 352px;
    }
    
    .text {
      padding: 0;
    }
  }
  
  @media (max-width: 1024px) {
    height: 506px;
  
    &.grid-ad {
      width: 305px;
    }
    
    .ad-logo {
      width: 90px;
      margin-bottom: 5px;
    }
    
    .text {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
    
    .price {
      font-size: 30px;
      line-height: 28px;
    }
    
    .link {
      width: 170px;
      height: 48px;
    }
  }
  
  @media (max-width: 991px) {
    height: 520px;
  
    &.grid-ad {
      width: 48%;
    }
  }
  
  @media (max-width: 480px) {
    --post-mr: 0;
  
    &.grid-ad {
      width: 100%;
      margin-right: var(--post-mr);
      margin-bottom: 40px;
    }
    
    .title {
      font-size: 32px;
      line-height: 30px;
    }
  }
`;