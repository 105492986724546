import React, { memo, useRef, useContext, useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql }                        from 'gatsby';
import { Swiper, SwiperSlide }                                  from 'swiper/react';
import SwiperCore, { Navigation, Pagination }                   from 'swiper/core';
import { Helmet }                                               from 'react-helmet';

// import constants
import { API_URL, BACKEND_URL } from '../../../constants';

// import context
import { GlobalStateContext } from '../../../context/GlobalContextProvider';

// import helpers
import { useWindowSize, usePostsById } from '../../../helpers';

// import assets
import tempPostImgVertical   from '../../../assets/images/featured-slider__img.png';
import tempPostImgHorizontal from '../../../assets/images/featured-slider__img__mobile.png';

// import styles
import { FeaturedSliderElement } from './styles';

// import components
import FeaturedSliderNavPrev      from './FeaturedSliderNavPrev';
import FeaturedSliderNavNext      from './FeaturedSliderNavNext';
import FeaturedSliderItemSkeleton from './FeaturedSliderItemSkeleton';
import ShareAction                from '../ShareBox/ShareAction';

// install Swiper modules
SwiperCore.use( [Navigation, Pagination] );

const FeaturedSlider = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          featured {
            post_ids
          }
        }
      }
      
      allPosts {
        nodes {
          lang
          postId
          id
          data {
            title
            slug
            short_description
            date
            category
            image_featured
            image_featured_alt
            image_regular
            image_regular_alt
            author {
              image
              image_alt
              name
            }
            global_texts {
              comments_text_single
              comments_text_multiple
              read_more_text
            }
          }
        }
      }
    }
  `);

  // define the component context
  const state = useContext( GlobalStateContext );
  const { defaultLang } = state;

  // define the default component state
  const [allPosts, setAllPosts]           = useState( [] );
  const [featuredPosts, setFeaturedPosts] = useState( [] );
  const [currentIndex, setCurrentIndex]   = useState( '01' );
  const [postsComments, setPostsComments] = useState( [] );

  // define the component custom hook
  const { width } = useWindowSize();
  const findingPosts = usePostsById( allPosts, featuredPosts );

  // define the Slider navigation
  const navigationPrevRef = useRef( null );
  const navigationNextRef = useRef( null );

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    const { allApiPoints, allPosts } = data;

    allApiPoints.nodes.forEach( node => {
      const { featured } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      setFeaturedPosts( featured[orderNumber].post_ids );
    });

    const posts = allPosts.nodes.map( node => {
      const {
        id, lang, postId,
        data: {
          short_description, slug, title, date, category, image_featured, image_featured_alt,
          image_regular, image_regular_alt,
          global_texts: { comments_text_single, comments_text_multiple, read_more_text },
          author: { image, image_alt, name }
        }
      } = node;

      return {
        id,
        postId,
        postLang: lang,
        postShortDescription: short_description,
        postSlug: slug,
        postTitle: title,
        postDate: date,
        postCategory: category,
        postAuthorData: { authorImg: image, authorImgAlt: image_alt, authorName: name },
        postCommentsTextSingle: comments_text_single,
        postCommentsTextMultiple: comments_text_multiple,
        postImage: image_featured,
        postImageAlt: image_featured_alt,
        postImageMobile: image_regular,
        postImageMobileAlt: image_regular_alt,
        postReadMore: read_more_text
      };
    });

    setAllPosts( posts );
  }, [data, defaultLang] );

  /**
   * Update post data
   */
  useEffect( () => {
    fetch( API_URL + 'blog_new/posts-sort-by-date-ascending/?lang=' + defaultLang )
      .then( response => response.json() )
      .then( data => {
        setPostsComments( data.filter_results )
      } );
  }, [defaultLang] );

  /**
   * Handle swiper
   *
   * @param swiper
   */
  const handleOnSwiper = swiper => {
    // Delay execution for the refs to be defined
    setTimeout( () => {
      // Override prevEl & nextEl now that refs are defined
      swiper.params.navigation.prevEl = navigationPrevRef.current;
      swiper.params.navigation.nextEl = navigationNextRef.current;

      // Re-init navigation
      swiper.navigation.destroy();
      swiper.navigation.init();
      swiper.navigation.update();
    })
  };

  /**
   * Handle slide change
   *
   * @param swiper
   */
  const handleChangeSlider = ( swiper ) => {
    const { realIndex } = swiper;
    const fixedIndex    = realIndex + 1;

    setCurrentIndex( fixedIndex > 9 ? fixedIndex : '0' + fixedIndex );
  };

  /**
   * Make render
   */
  if ( findingPosts.length > 0 ) {
    const postsCounter = findingPosts.length > 9 ? findingPosts.length : '0' + findingPosts.length;

    if ( width > 480 ) {
      return (
        <FeaturedSliderElement>
          <Helmet>
            <link rel="preload" as="image" href={ BACKEND_URL + findingPosts[0].postImage } />
          </Helmet>
          <Swiper className="featured-posts"
                  slidesPerView={ 'auto' }
                  loop={ true }
                  observer={ true }
                  observeParents={ true }
                  pagination={ { clickable: true } }
                  breakpoints={{
                    481: {
                      spaceBetween: 50
                    },
                    992: {
                      spaceBetween: 60
                    },
                    1441: {
                      spaceBetween: 80
                    }
                  }}
                  onSwiper={ swiper => handleOnSwiper( swiper ) }
                  onSlideChange={ swiper => handleChangeSlider( swiper ) }>
            { findingPosts.map( slide => {
              const {
                id, postDate, postCategory, postTitle, postShortDescription, postSlug, postImage, postImageAlt,
                postCommentsTextSingle, postCommentsTextMultiple,
                postAuthorData: { authorImg, authorImgAlt, authorName }, postReadMore, postId
              } = slide;

              let commentsCount = 0;

              postsComments.forEach( post => {
                if ( post.id === postId ) {
                  commentsCount = post.total_comments;
                }
              });

              const postImageVertical   = postImage !== null ? BACKEND_URL + postImage : tempPostImgVertical;
              const commentsText = commentsCount === 1 ? postCommentsTextSingle : postCommentsTextMultiple;
              const postUrl = defaultLang === 'de' ? `/${ postSlug }` : `/en/${ postSlug }`;

              return(
                <SwiperSlide className="post" key={ id }>
                  <div className="left-column">
                    <Link className="post__img__link" to={ postUrl } title={ postTitle }>
                      <img className="post__img"
                           src={ postImageVertical }
                           alt={ postImageAlt } />
                    </Link>
                  </div>

                  <div className="right-column">
                    <div className="right-column__wrapper">
                      <div className="post__top">
                        <div className="post__header">
                          <span className="post__date">{ postDate }</span>
                          <div className="post__actions">
                            <ShareAction wrapperClass="post__actions__share__wrapper"
                                         staticClass="post__actions__share"
                                         activeClass="post__actions__share post__actions__share--is-active"
                                         shareBoxPosition={ "right" }
                                         shareIconClass="post__actions__share__icon"
                                         postTitle={ postTitle }
                                         postUrl={ postUrl } />
                          </div>
                        </div>
                        <span className="post__category">{ postCategory }</span>
                        <h2 className="post__title">
                          <Link className="post__title__link" to={ postUrl }>{ postTitle }</Link>
                        </h2>
                        <p className="post__short-description">{ postShortDescription }</p>
                      </div>

                      <div className="post__bottom">
                        <div className="post__author">
                          <img className="post__author__img"
                               src={ BACKEND_URL + authorImg }
                               alt={ authorImgAlt } />

                          <div className="post__author__data">
                            <span className="post__author__name">{ authorName }</span>
                            <div className="post__author__comments">
                              <span className="post__author__comments__string">{ commentsCount } { commentsText }</span>
                            </div>
                          </div>
                        </div>

                        <Link className="post__read-more" to={ postUrl }>{ postReadMore }</Link>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              )
            } ) }

            <div className="featured-posts__nav">
              <div className="featured-posts__nav__item featured-posts__nav__item--prev" ref={ navigationPrevRef }>
                <FeaturedSliderNavPrev />
              </div>
              <div className="featured-posts__nav__item featured-posts__nav__item--next" ref={ navigationNextRef }>
                <FeaturedSliderNavNext />
              </div>
            </div>
          </Swiper>

          <div className="featured-posts__nav__counter">
            <span className="featured-posts__nav__counter__current">{ currentIndex }</span>
            <span className="featured-posts__nav__counter__separator" />
            <span className="featured-posts__nav__counter__total">{ postsCounter }</span>
          </div>
        </FeaturedSliderElement>
      )
    } else {
      return (
        <FeaturedSliderElement>
          <Helmet>
            <link rel="preload" as="image" href={ BACKEND_URL + findingPosts[0].postImageMobile } />
          </Helmet>
          <Swiper className="featured-posts"
                  slidesPerView={ 'auto' }
                  loop={ true }
                  observer={ true }
                  observeParents={ true }
                  pagination={ { clickable: true } }
                  centeredSlides={ true }
                  onSlideChange={ swiper => handleChangeSlider( swiper ) }>
            { findingPosts.map( slide => {
              const {
                id, postDate, postCategory, postTitle, postShortDescription, postSlug,
                postImageMobile, postImageMobileAlt, postCommentsTextSingle, postCommentsTextMultiple,
                postAuthorData: { authorImg, authorImgAlt, authorName }, postReadMore, postId
              } = slide;

              let commentsCount = 0;

              postsComments.forEach( post => {
                if ( post.id === postId ) {
                  commentsCount = post.total_comments;
                }
              });

              const postImageHorizontal = postImageMobile !== null ? BACKEND_URL + postImageMobile : tempPostImgHorizontal;
              const commentsText = commentsCount === 1 ? postCommentsTextSingle : postCommentsTextMultiple;
              const postUrl = defaultLang === 'de' ? `/${ postSlug }` : `/en/${ postSlug }`;

              return(
                <SwiperSlide className="post" key={ id }>
                  <div className="left-column">
                    <Link className="post__img__link" to={ postUrl } title={ postTitle }>
                      <img className="post__img"
                           src={ postImageHorizontal }
                           alt={ postImageMobileAlt } />
                    </Link>
                  </div>

                  <div className="right-column">
                    <div className="right-column__wrapper">
                      <div className="post__top">
                        <div className="post__header">
                          <span className="post__category">{ postCategory }</span>
                          <span className="post__date">{ postDate }</span>
                        </div>
                        <h2 className="post__title">
                          <Link className="post__title__link" to={ postUrl }>{ postTitle }</Link>
                        </h2>
                        <p className="post__short-description">{ postShortDescription }</p>
                      </div>

                      <div className="post__bottom">
                        <div className="post__author">
                          <img className="post__author__img"
                               src={ BACKEND_URL + authorImg }
                               alt={ authorImgAlt } />

                          <div className="post__author__data">
                            <span className="post__author__name">{ authorName }</span>
                            <div className="post__author__comments">
                              <span className="post__author__comments__string">{ commentsCount } { commentsText }</span>
                            </div>
                          </div>
                        </div>

                        <div className="post__bottom__actions">
                          <div className="post__actions">
                          <span className="post__actions__item">
                            <ShareAction wrapperClass="post__actions__share__wrapper"
                                         staticClass="post__actions__share"
                                         activeClass="post__actions__share post__actions__share--is-active"
                                         shareBoxPosition="left"
                                         shareIconClass="post__actions__share__icon"
                                         postTitle={ postTitle }
                                         postUrl={ postUrl } />
                          </span>
                          </div>
                          <Link className="post__read-more" to={ postUrl }>{ postReadMore }</Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <FeaturedSliderItemSkeleton />
                </SwiperSlide>
              )
            } ) }
          </Swiper>
        </FeaturedSliderElement>
      )
    }
  } else return false;
});

export default FeaturedSlider;