import React, { memo } from 'react';
import PropTypes       from 'prop-types';

// import styles
import { TitleElement } from '../../styles/elements/Title';

const Title = memo( ( { type, text, additionalClass } ) => {
  return (
    <TitleElement>
      { type === 'h1' ? <h1 className={ `title title--h1 ${ additionalClass }` }>{ text }</h1> : false }
      { type === 'h2' ? <h2 className={ `title title--h2 ${ additionalClass }` }>{ text }</h2> : false }
    </TitleElement>
  )
});

Title.defaultProps = {
  type: 'h1',
  text: '',
  additionalClass: ''
};

Title.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  additionalClass: PropTypes.string
};

export default Title;