import React, { memo, useContext, useState, useEffect } from 'react';
import { useStaticQuery, graphql }                      from 'gatsby';

// import constants
import { CHANGE_FILTER, GET_FILTERED_POSTS, CHANGE_SORT_TITLE } from '../../../constants';

// import context
import { GlobalDispatchContext, GlobalStateContext } from '../../../context/GlobalContextProvider';

// import styles
import { FilterElement }     from './styles/index';
import { FilterItemElement } from './styles/FilterItem';

// import components
import FilterItem from './FilterItem';
import CrossIcon  from '../icons/CrossIcon';

const Filter = memo( () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          sort_filters {
            title_filter
            title_sort
            clear_filters
            filters {
              title
              values {
                title
                value
                type
              }
            }   
            sort_urls {
              title
              value
              type
            }
          }
        }
      }
    }
  `);

  // get the app global state
  const state    = useContext( GlobalStateContext );
  const dispatch = useContext( GlobalDispatchContext );
  const { defaultLang, sortDropdownTitle, filterRequestData } = state;

  // define the default component state
  const [filterIsShown, setFilterIsShown] = useState( false );
  const [filters, setFilters] = useState( [] );
  const [sorting, setSorting] = useState( [] );
  const [isClearActive, setIsClearActive] = useState( false );
  const [texts, setTexts] = useState({
    filterTitle: '',
    sortTitle: '',
    clearFilter: ''
  });

  const { filterTitle, sortTitle, clearFilter } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { sort_filters } = node;
      let orderNumber;

      switch ( defaultLang ) {
        case 'en':
          orderNumber = 1;
          break;

        default:
          orderNumber = 0;
      }

      const current = sort_filters[orderNumber];
      const { title_filter, title_sort, filters, sort_urls, clear_filters } = current;

      setFilters( filters );
      setSorting( sort_urls );
      setTexts({
        filterTitle: title_filter,
        sortTitle: title_sort,
        clearFilter: clear_filters
      });
      dispatch( { type: CHANGE_SORT_TITLE, payload: sort_urls[0].title } )
    });
  }, [data, defaultLang, dispatch] );

  /**
   * Add the scroll event
   */
  useEffect( () => {
    const handleScroll = () => {
      if ( window.scrollY > 400 ) {
        setFilterIsShown( true );
      } else setFilterIsShown( false );
    };

    window.addEventListener( 'scroll', handleScroll );

    return () => {
      window.removeEventListener( 'scroll', handleScroll );
    }
  }, [] );

  /**
   * Make the Clear filter button disable or enable
   */
  useEffect( () => {
    const { author, year, time, level, sort } = filterRequestData;
    const sortValSecond = sorting.length > 0 ? sorting[1].value : '';
    const sortValThird  = sorting.length > 0 ? sorting[2].value : '';

    if ( author !== '' || year !== '' || time !== '' || level !== '' || sort === sortValSecond || sort === sortValThird ) {
      setIsClearActive( true );
    } else setIsClearActive( false );
  }, [filterRequestData, sorting] );

  /**
   * Clear all filters
   */
  const handleClickClearFilters = () => {
    const data = {
      author: '',
      year: '',
      time: '',
      level: '',
      sort: sorting[0].value
    };

    dispatch( { type: CHANGE_FILTER, payload: data } );

    fetch( data.sort )
      .then( response => response.json() )
      .then( data => {
        const { filter_results } = data;
        dispatch( { type: GET_FILTERED_POSTS, payload: filter_results } );
        dispatch( { type: CHANGE_SORT_TITLE, payload: sorting[0].title } );
      });
  };

  return (
    <FilterElement className={ filterIsShown ? 'is-shown' : '' }>
      <div className="filter-wrapper">
        <div className="container wrapper">
          <div className={ defaultLang === 'de' ? 'filter filter--de' : 'filter' }>
            <span className="title">{ filterTitle }</span>
            <div className="list">
              { filters.map( filter => {
                const { title, values } = filter;

                return <FilterItem key={ title }
                                   title={ title }
                                   values={ values }
                                   isDefault={ false } />;
              })}

              <FilterItemElement>
                <span className={ isClearActive ? 'item clear-filter' : 'item clear-filter clear-filter--inactive' }
                      role="presentation"
                      onClick={ handleClickClearFilters }>
                  { clearFilter } <CrossIcon className="clear-filter__icon" color="#778186" />
                </span>
              </FilterItemElement>
            </div>
          </div>

          <div className={ defaultLang === 'de' ? 'sort sort--de' : 'sort' }>
            <span className="title">{ sortTitle }</span>
              <FilterItem title={ sortDropdownTitle }
                          values={ sorting }
                          isDefault={ true }
                          filterType="sort" />
          </div>
        </div>
      </div>
    </FilterElement>
  )
});

export default Filter;