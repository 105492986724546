import React, { memo } from 'react';
import PropTypes       from 'prop-types';

const DecorativeArrows = memo( ( { className } ) => {
  return (
    <svg className={ className }
         xmlns="http://www.w3.org/2000/svg"
         width="110.571"
         height="110.572"
         viewBox="0 0 110.571 110.572">
      <g transform="translate(384.251 507.707) rotate(180)">
        <path d="M320.8,488.533H273.68V441.413a2.7,2.7,0,0,1,5.408,0v41.711H320.8a2.7,2.7,0,0,1,0,5.408Z"
              transform="translate(0 19.174)"
              fill="#543a8e"/>
        <path d="M334.656,474.672H287.538V427.555a2.7,2.7,0,0,1,5.408,0v41.708h41.71a2.7,2.7,0,1,1,0,5.408Z"
              transform="translate(6.391 12.783)"
              fill="#00b7f1"/>
        <path d="M301.4,460.815V413.7a2.7,2.7,0,0,1,5.408,0v41.71h41.711a2.7,2.7,0,0,1,0,5.408Z"
              transform="translate(12.783 6.392)"
              fill="#543a8e"/>
        <path d="M315.255,446.956V399.84a2.7,2.7,0,1,1,5.406,0v41.709l41.711,0a2.7,2.7,0,0,1,0,5.408Z"
              transform="translate(19.174)"
              fill="#00b7f1"/>
      </g>
    </svg>
  )
});

DecorativeArrows.defaultProps = {
  className: ''
};

DecorativeArrows.propTypes = {
  className: PropTypes.string
};

export default DecorativeArrows;