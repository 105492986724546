import styled from 'styled-components';

export const TrendingElement = styled.div`  
  /*-------------------------
  # Media Queries
  --------------------------*/ 
  @media (max-width: 1024px) {
    .date {
      line-height: 18px;
    }
  }
  
   @media (max-width: 991px) {
    .trending {
      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
   }
   
   @media (max-width: 480px) {
    padding: 0 35px;
   
    .title {
      display: block;
      margin-bottom: 24px;
    }
   }
   
   @media (max-width: 375px) {
    padding: 0 20px;
   }
`;