import styled from 'styled-components';

export const NewsletterElement = styled.div`
  margin-bottom: 60px;
  border-radius: 8px;
  background: linear-gradient(180deg, #00ACEF, #291663);
  
  &.wide {
    --field-width: 700px;
  
    padding: 40px 40px 60px 90px;
    
    .text {
      width: calc(100% - var(--field-width));
      padding-right: 50px;
    }
    
    .field-group {
      width: var(--field-width);
    }
  }
  
  .title {
    margin: 0 0 15px 0;
    font-size: 48px;
    font-weight: 800;
    line-height: 47px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: var(--white);
  }
  
  .content {
    display: flex;
    justify-content: space-between;
  }
  
  .text {
    margin: 0;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: 1.2px;
    color: var(--white);
  }
  
  .field {
    width: 100%;
    height: 70px;
    border: none;
    border-radius: 48px;
    outline: none;
    padding: 20px 100px 20px 45px;
    letter-spacing: 1.25px;
    color: var(--theme-deep-purple);
    
    &::placeholder {
      color: var(--theme-gray);
    }
    
    &-group {
      position: relative;
      height: 100%;
      
      &.is-loading {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: .25;
          border-radius: 48px;
          background-color: var(--theme-blue);
          z-index: 1;
        }
      }
    }
    
    &__btn {
      position: absolute;
      top: 14px;
      right: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      border: 2px solid var(--theme-blue);
      border-radius: 50%;
      padding: 0;
      background-color: transparent;
      transition: .3s all ease-in-out;
      
      &:hover {
        transform: rotate(1turn);
        cursor: pointer;
      }
      
      &__arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  .error {
    position: absolute;
    left: 35px;
    bottom: -30px;
    font-weight: 700;
    color: var(--theme-error);
  }
  
  .loading {
    --loading-width: 70px;
    --loading-height: 18px;
  
    position: absolute;
    left: calc(50% - (var(--loading-width) / 2));
    top: calc(50% - (var(--loading-height) / 2));
    width: var(--loading-width);
    text-align: center;
    z-index: 2;
    
    &__item {
      display: inline-block;
      width: var(--loading-height);
      height: var(--loading-height);
      border-radius: 100%;
      background-color: var(--theme-purple);
      animation: newsletterLoading 1.4s infinite ease-in-out both;
      
      &--1 {
        animation-delay: -0.32s;
      }
      
      &--2 {
        animation-delay: -0.16s
      }
    }
  }

  @keyframes newsletterLoading {
    0%, 80%, 100% { 
      transform: scale(0);
    }
    
    40% {
      transform: scale(1.0);
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    &.wide {
      --field-width: 595px;
    
      padding-left: 50px;
    }
  }
  
  @media (max-width: 1280px) {
    &.wide {
      --field-width: 535px;
    
      padding: 38px 15px 50px 42px;
    }
  }
  
  @media (max-width: 1280px) {
    &.wide {
      --field-width: 425px;
    
      padding: 20px;
    }
    
    .title {
      font-size: 40px;
      line-height: 40px;
    }
  }
  
  @media (max-width: 991px) {
    &.wide {
      padding-bottom: 40px;
    
      .text {
        width: 100%;
        margin-bottom: 10px;
        padding-right: 0;
      }
      
      .field-group {
        width: 444px;
      }
      
      .field {
        height: 50px;
        padding: 10px 50px 10px 25px;
        
        &__btn {
          top: 9px;
          right: 8px;
          width: 32px;
          height: 32px;   
        }
      }
    }
    
    .content {
      flex-direction: column;
    }
    
    .error {
      left: 20px;
    }
  }
  
  @media (max-width: 480px) {
    &.wide {
      .title {
        font-size: 32px;
        font-weight: 700;
        line-height: 28px;
      }
      
      .text {
        font-size: 14px;
        line-height: 22px;
      }
      
      .field-group {
        width: 100%;
      }
    }
    
    .error {
      font-size: 12px;
    }
  }
`;