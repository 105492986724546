import React, { memo } from 'react';

const FeaturedSliderItemSkeleton = memo( () => {
  return (
    <div className="skeleton">
      <svg xmlns="http://www.w3.org/2000/svg"
           width="288.898"
           height="113.858"
           viewBox="0 0 288.898 113.858">
        <rect width="288.897" height="113.858" fill="#e2f2f7"/>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg"
           width="281.967"
           height="294.387"
           viewBox="0 0 281.967 294.387">
        <g transform="translate(0.752 243.85)" fill="none" stroke="#e3e4e5" strokeWidth="2">
          <circle cx="25.268" cy="25.268" r="25.268" stroke="none"/>
          <circle cx="25.268" cy="25.268" r="24.268" fill="none"/>
        </g>
        <line x2="276.321"
              transform="translate(0.342 183.434)"
              fill="none"
              stroke="#707070"
              strokeWidth="1"/>
        <g transform="translate(123.422 258.903)">
          <rect width="158.545" height="35.483" rx="17.742" fill="#e3e4e5"/>
        </g>
        <rect width="128.66" height="11.889" rx="4" transform="translate(6.666 199.967)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0 70.754)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0 92.028)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0 113.301)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0 131.364)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0 152.638)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="235.333" height="13.083" rx="4" transform="translate(0.752)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="269.145" height="17.918" rx="4" transform="translate(0.752 21.273)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="269.145" height="17.918" rx="4" transform="translate(0.752 42.201)" fill="#d1d3d4" opacity="0.541"/>
        <rect width="155.558" height="10.259" rx="4" transform="translate(65.145 221.638)" fill="#d1d3d4" opacity="0.541"/>
      </svg>
    </div>
  );
});

export default FeaturedSliderItemSkeleton;