import React, { memo } from 'react';
import { Link }        from 'gatsby';
import PropTypes       from 'prop-types';

// import helpers
import { useWindowSize } from '../../helpers';

// import styles
import { TrendingPostPreviewElement } from '../../styles/elements/TrendingPostPreview';

const TrendingPostPreview = memo( ( { data } ) => {
  // define the custom hook
  const { width } = useWindowSize();

  const { postTitle, postShortDescription, postDate, postSlug, currentLang } = data;
  const postUrl = currentLang === 'de' ? `/${ postSlug }` : `/en/${ postSlug }`;

  return (
    <TrendingPostPreviewElement>
      <span className="date">{ postDate }</span>
      <h3 className="title">
        <Link className="title__link" to={ postUrl }>{ postTitle }</Link>
      </h3>
      { width <= 991 || width > 1024 ?
        <p className="description">{ postShortDescription }</p>
      : false }
    </TrendingPostPreviewElement>
  )
});

TrendingPostPreview.defaultProps = {
  data: {}
};

TrendingPostPreview.propTypes = {
  data: PropTypes.object.isRequired
};

export default TrendingPostPreview;