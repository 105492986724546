import styled from 'styled-components';

export const PostsListElement = styled.div`  
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  
  &::after {
    content: '';
    flex: auto;
  }
`;