import styled from 'styled-components';

export const LoadMoreBtnElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  .load {
    --decorative-width: 555px;
    --decorative-offset: -65px;
  
    position: relative;
    width: 200px;
    height: 57px;
    border: 2px solid var(--theme-blue);
    border-radius: 48px;
    padding-right: 25px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-transform: uppercase;
    color: var(--theme-blue);
    background-color: var(--white);
    
    &:hover {
      cursor: pointer;
      
      .load {
        &__arrow {
          transform: rotate(1.25turn);
        }
      }
    }
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 25px;
      width: var(--decorative-width);
      height: 2px;
      background-color: var(--theme-gray);
    }
    
    &::before {
      left: calc((var(--decorative-width) * -1) + var(--decorative-offset));
    }
    
    &::after {
      right: calc((var(--decorative-width) * -1) + var(--decorative-offset));
    }
    
    &__arrow {
      --dimensions: 34px;
    
      position: absolute;
      top: calc(50% - (var(--dimensions) / 2));
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--dimensions);
      height: var(--dimensions);
      margin-left: 10px;
      border: 2px solid var(--theme-blue);
      border-radius: 50%;
      transform: rotate(90deg);
      transition: .3s all ease-in-out;
    }
  }
  
  /*-------------------------
  # Media Queries
  --------------------------*/
  @media (max-width: 1440px) {
    .load {
      --decorative-width: 500px;
      --decorative-offset: -40px;
    }
  }
  
  @media (max-width: 1280px) {
    .load {
      --decorative-width: 420px;
      --decorative-offset: -40px;
    }
  }
  
  @media (max-width: 1024px) {
    .load {
      --decorative-width: 300px;
    }
  }
  
  @media (max-width: 991px) {
    .load {
      --decorative-width: 200px;
    }
  }
  
  @media (max-width: 640px) {
    .load {
      --decorative-width: 120px;
    }
  }
  
  @media (max-width: 550px) {
    .load {
      --decorative-width: 80px;
    }
  }
  
  @media (max-width: 480px) {
    .load {
      --decorative-width: 50px;
      --decorative-offset: -20px;
      
      width: 180px;
      height: 52px;
      padding-right: 35px;
    }
  }
`;